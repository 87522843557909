<template>
  <a-layout>
    <a-layout-conten>
      <a-row type="flex" align="middle" style="height: calc(100vh - 62px)">
        <a-col class="login">
          <div v-if="isCallback">
            <a-spin class="spin" tip="Logging in..."> </a-spin>
          </div>
          <div v-else class="form">
            <img src="../../assets/springup-logo.svg" alt="" class="logo" />
            <div class="title">OA</div>
            <button
              class="login-with-gitlab"
              type="primary"
              size="large"
              @click.stop.prevent="onLogin"
            >
              <img src="../../assets/gitlab-icon.svg" alt="" />
              <span>Login with GitLab</span>
            </button>
          </div>
        </a-col>
      </a-row>
    </a-layout-conten>
    <a-layout-footer class="footer">&copy;2021 Springup Inc.</a-layout-footer>
  </a-layout>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { services } from "../../../config";
import { randomString } from "../../utils/utils";

import jwt from "jsonwebtoken";

export default {
  components: {},
  setup() {
    const v = reactive({
      loginText: "Logging in...",
      hasLoinLink: false,
      loading: false,
      isCallback: null,
    });
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onBeforeMount(async () => {
      if (route.query && route.query.code && route.query.state) {
        v.isCallback = true;
        await exchangeToken(route.query.code, route.query.state);
        return;
      }
    });

    async function exchangeToken(code, state) {
      try {
        const session_id = localStorage.getItem("session_id");
        const res = await proxy.$axios.openget(`/api/v1/auth`, {
          code,
          state,
          session_id,
        });
        if (res.status == 200) {
          proxy.$msg.success("Login success");
          if (res.data) {
            let str = jwt.decode(res.data.accessToken);
            // console.log(str);

            if (str) {
              let obj = str;
              obj.token = res.data.accessToken;
              obj.refreshToken = res.data.refreshToken;
              obj.exp = obj.exp * 1000;
              (obj.user = str.user),
                localStorage.setItem("_tokenInfo", JSON.stringify(obj));
              localStorage.setItem("_userInfo", JSON.stringify(obj.user));
              store.commit("SET_TOKENINFO", obj);
              store.commit("SET_LOGIN_STATUS", true);
              store.commit("SET_USERNAME", str.user.username);
              store.commit("SET_USERINFO", str.user);
              // store.commit("SET_SUPERUSER", str.super_user);
              store.commit("SET_ROLES", str.user.roles);

              router.push("/");
            } else {
              v.noAdmin = true;
            }
          }
        } else {
          proxy.$msg.error("Failed!");
        }
      } catch (err) {
        if (err.response)
          switch (err.response.status) {
            case 401: {
              v.wrongPwd = true;
              return;
            }
            case 404: {
              v.userExist = true;
              return;
            }
          }
        proxy.$msg.error("Network error please try again last");
      } finally {
        localStorage.removeItem("session_id");
      }
    }

    async function onLogin() {
      let session_id = randomString(16);
      localStorage.setItem("session_id", session_id);

      window.location.href = `${services.oa_server}/api/v1/login/gitlab?session_id=${session_id}`;
    }

    return {
      ...toRefs(v),
      onLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  max-width: 391px;
  padding: 25px 0;
  margin: auto;
  text-align: center;
  border-radius: 5px;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 256px;
      margin-bottom: 100px;
    }

    .title {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: 900;
      line-height: 42px;
    }

    .login-with-gitlab {
      width: 100%;
      background-color: #292961;
      border: #292961;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 2px;

      cursor: pointer;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 45px;
      }
    }
  }

  .spin {
    left: 50%;
  }
}

@media (min-width: 992px) {
  .login {
    padding: 30px 30px;
  }
}

.footer {
  text-align: center;
}
</style>
